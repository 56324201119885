import * as signalR from "@microsoft/signalr";
import { OrderState, SaleData } from "../../features/order-status/orderStatusSlice";
import { CreateOrderResponse } from "../../store/signalr";

export interface  ILiteHubClient {
     OrderPayResult(success:boolean):void;
     BuyResult(success:boolean):void;
     OrderPayPreResult(success:boolean):void;
     BuyPreResult(success:boolean):void;
     SaleStart(percent:number):void;
     SaleStartV2(saleData:SaleData):void
     OrderBoxOpened():void;
     OrderStatus(order: OrderState):void;
     CreateOrderResult( order:CreateOrderResponse):void;
     OrderPayed( order:OrderState):void;
     ReloadCatalogs():void;
     ReloadPage():void;
     ToHome():void;
}
export class LiteHub  {
    connection!:signalR.HubConnection
    started = false;
    start(host: string,client:ILiteHubClient) :void{
      
        if(this.started){
            return
        }
    
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${host}/api/LiteHub`)
            .configureLogging(signalR.LogLevel.Trace)
            .build();
        
        this.connection.on('OrderPayResult', (obj) => {
          client.OrderPayResult(obj)
        });
        this.connection.on('BuyResult', (obj) => {
          client.BuyResult(obj)
        });
        this.connection.on('OrderPayPreResult', (obj) => {
          client.OrderPayPreResult(obj)
        });
        this.connection.on('BuyPreResult', (obj) => {
          client.BuyPreResult(obj)
        });
        this.connection.on('SaleStart', (obj) => {
          client.SaleStart(obj.discount_percent)
        });
        this.connection.on('SaleStartV2', (obj) => {
          client.SaleStartV2(obj as SaleData)
        });
        this.connection.on('OrderBoxOpened', () => {
          client.OrderBoxOpened()
        });
        this.connection.on('CreateOrderResult', (obj) => {
          client.CreateOrderResult(obj)
        });
        this.connection.on('OrderPayed', (obj) => {
          client.OrderPayed(obj)
        });
        this.connection.on('OrderStatus', (obj) => {
          client.OrderStatus(obj)
        });
        this.connection.on('ReloadCatalogs', (obj) => {
          client.ReloadCatalogs()
        });
        this.connection.on('ReloadPage', (obj) => {
          client.ReloadPage()
        });
        // this.connection.on('PinCancel', (obj) => {
        //   client.PinCancel()
        // });
        this.connection.on('ToHome', (obj) => {
          client.ToHome()
        });
        this.connection.onreconnected((connectionID) => {
            console.log('onreconnected ' + connectionID);
        })
        this.connection.onreconnecting((err) => {
            console.log('onreconnecting ' + err);
        })


        this.connection.onclose((err: Error|undefined) => {
            console.log('onclose ' + err);
            this.connect();
        })
        this.connect();
        this.started = true
    }
    connect() {
        this.connection.start().then(res => {
            //this.connection.invoke()
            console.log('CONNECT');
        }).catch(err => {

           setTimeout(() => this.connect(), 200)
            console.log(err);
            console.log('DISCONNECT');
        });
    }
}
