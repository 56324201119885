import { Product } from '../../../../client/model/BoxSdk/UIModel/product';

export interface PromoData{
	default_dialog: string,
	expiration_date: string,
	default_percent: number,
	default_qr: string
}
export interface Config{
	is_maintenance:boolean
	number_check:boolean
	phone:string
	box_id:number
	is_free_order:boolean
	veryfication_type:number
	promo:PromoData
}
export class Category {
	id!: number;
	name!: string;
	img!: string;
	disabled!: boolean;
	min_discount_price!: number;
	min_price!: number;
	products!: Array<Product>;
}
export interface Categories<T>{
	is_sale:boolean
	is_maintenance:boolean
	items:[Category]
}

export class PhoneServerModel {
	id!: number;
	image!: string;
	name!: string;
	additional_name?: string;
}

export class Factory {
	id!: number;
	phone_model!: PhoneServerModel[];
	name!: string;
	image!: string;
}
