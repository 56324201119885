// import { useHistory } from 'react-router-dom';
import './header.scss';
import userSmartHistory from "../../metrica/metrica";
export function Header() {
    let navigate = userSmartHistory();
    return (<header  onClick={()=>{navigate.push('/')}}>
        <div className="container header-container">
            <div className="header_logo">
                <div className={"header_logo_text"}>
                    <span className="primaryText">РЕМОНТ</span>&nbsp;ТЕЛЕФОНОВ
                </div>
                <div className='record-mark'>
                    <div className="record-icon">
                        <img src='/assets/img/icons/record-in.svg' className='blink'></img>
                        <img src='/assets/img/icons/record-out.svg'></img>
                    </div>
                    <div className="record-text">Ведется видеонаблюдение</div>
                </div>
            </div>
        </div>
    </header>)
}
