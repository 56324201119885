import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"
export interface SupportWindowState {
  
 
  isSupport: boolean,

}

const initialState: SupportWindowState = {
  isSupport: false,
}

export const supportWindow = createSlice({
  name: 'SupportWindow',
  initialState,
  reducers: {
    closeWindow: (state) => {
      state.isSupport = false
      debugger;
     
    },
    openWindow: (state) => {
      state.isSupport = true
    },
    timeTick:(state)=>{
      
    },
   
    lastTouch:(state)=>{
    
    },
   
  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state) => {

    });

  }
})

export const { closeWindow,lastTouch, timeTick, openWindow } = supportWindow.actions

export const selectIsOpen = (state: RootState) => {
  return state.home.isMaintenance
}


export default supportWindow.reducer
