import { createSlice } from "@reduxjs/toolkit"
import { orderPaymentFailed, loadOrderStatus, orderPaymentSuccess } from "../../network/actions";
import { orderPayed, orderStatus } from "../../store/actions";
import { RootState } from "../../store/store"

export enum LoadingStatus {
    None,
    Loading,
    Loaded,
    Error
}
export interface OrderData {
    number: string;
    status: string;
    order_status: number;
    payed:boolean
    date: number
}
export interface UserData {
    name: string;
    last_name: string;
    phone_number: string;
}

export interface PhoneData {
    imei: string;
    brand: string;
    model: string;
}
export interface RepairDataItem {
    name: string
    price: number
    count: number
}
export interface RepairData {
    price: number;
    date: number;
    date_closed: string | null;
    warranty_date: string | null;
    closed_date: string | null;
    paied: boolean;
    task_secret: string
    repair_item: RepairDataItem[]
}
export interface OrderState {
    secret: string
    order: OrderData
    user: UserData
    phone: PhoneData
    repair: RepairData
}
export interface OrderStatusState {

    status: LoadingStatus;
    data: OrderState | null;
    promptShow: boolean;
    showSbpDialog: boolean;
    showCardDialog: boolean;
    showLicenceDialog: boolean;
    isWaitReceipt: boolean;
}
export interface SaleData{
    discount_percent: number
    is_show_alert: boolean
    is_free_order: boolean
    expire_date: string
    dialog_type: string
}
const initialState: OrderStatusState = {

    status: LoadingStatus.None,
    promptShow: false,
    showSbpDialog: false,
    showCardDialog: false,
    showLicenceDialog: false,
    isWaitReceipt: false,

    data: null
    // data :{
    //     order: {
    //         number: '1234567',
    //         date: new Date().getTime()
    //     }as OrderData,
    //     user:{
    //         phone_number:'+7(123)456-78-90',
    //         name: 'Иван',
    //         last_name: 'Иванов',
    //     },
    //     phone:{
    //         imei: '35-419002-389644-3',
    //         brand:  'Xiaomi',
    //         model:  'Redmi 6',
    //     } as PhoneData,
    //     repair: {
    //         price: 12000,
    //         warranty_date: 0,
    //         date:0,
    //         paied:false
    //     } as RepairData
    // } as OrderState
}
export const orderStatusSlice = createSlice({
    name: 'orderStatus',
    initialState,
    reducers: {
        promptOpener: (state) => {
            state.promptShow = !state.promptShow;
        },
        toggleSbpDialog: (state) => {
            state.showSbpDialog = !state.showSbpDialog;
        },
        toggleCardDialog: (state) => {
            state.showCardDialog = !state.showCardDialog;
        },
        toggleShowLicence: (state) => {
            state.showLicenceDialog = !state.showLicenceDialog;
        },
        waitOrderReceipt: (state) => {
            state.isWaitReceipt = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadOrderStatus.pending, (state, action) => {
            state.status = LoadingStatus.Loading
        });

        builder.addCase(orderStatus, (state, action) => {
            state.data = action.payload
          
        });
        builder.addCase("CLEANUP", (state) => {
            state.data = null
            state.isWaitReceipt = false;

        });
        builder.addCase(orderPaymentFailed, (state, action) => {
            state.showCardDialog = false;

        });
        builder.addCase(orderPaymentSuccess, (state, action) => {
            state.showCardDialog = false;
            state.showSbpDialog = false;
            let orderState = state.data;
            if (orderState != null) {
                orderState.repair.paied = true;
                state.data = orderState;
            }

        });

        builder.addCase(loadOrderStatus.fulfilled, (state, action) => {
            state.data = action.payload
            state.showLicenceDialog = false
            debugger
            //state.data?.orderNumber = action.payload.order.

            /*
             orderNumber: '1234567',
        orderClient: 'Иван Иванов',
        firstPhoneNumber: '+7(123)456-78-90',
        secondPhoneNumber: null,
        imei: '35-419002-389644-3',
        phoneBrand: 'Xiaomi',
        phoneModel: 'Redmi 6',
        orderPrice: 12000,
        orderDate: 0,
        warrantyDate: 0,
         */
        })
    }
})

export const {
    promptOpener,
    toggleShowLicence,
    toggleSbpDialog,
    toggleCardDialog,
    waitOrderReceipt
} = orderStatusSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectOrderStatusState = (state: RootState) => state.orderStatus.data
export const selectLoadingStatusState = (state: RootState) => state.orderStatus.status
export const selectPromptShowState = (state: RootState) => state.orderStatus.promptShow
export const selectShowSbpState = (state: RootState) => state.orderStatus.showSbpDialog
export const selectShowCardState = (state: RootState) => state.orderStatus.showCardDialog
export const selectShowLicenceState = (state: RootState) => state.orderStatus.showLicenceDialog
export const selectPaied = (state: RootState) => {
    console.log(state.orderStatus.data?.order?.payed)
    return state.orderStatus.data?.order?.payed
}
export const selectPrice = (state: RootState) => state.orderStatus.data?.repair?.price
export const selectIsWaitReceipt = (state: RootState) => state.orderStatus.isWaitReceipt
export const selectSecret = (state: RootState) => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.secret != null && params.secert != "") {
        return params.secret;
    }
    return state.orderStatus.data?.secret
}

export default orderStatusSlice.reducer
